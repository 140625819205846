import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './Modal.css';

const Modal = (props) => {
  const { onClose, show, children, title = '' } = props;
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className='modal' onClick={onClose}>
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h4 className='modal-title'>{title}</h4>
            </div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer'>
              <button onClick={onClose} className='btn btn-secondary'>
                {props.selectedLanguage.translation.returnButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('root')
  );
};

export default Modal;
