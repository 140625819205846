import React from 'react';
import './footer.css';

import visaIcon from '../../media/visa.png';
import mastercardIcon from '../../media/mastercard.png'
import americanexpress from '../../media/americanexpress.png'
import applepay from '../../media/applepay.png'

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-icons'>
      <img src={visaIcon} alt='Visa' />      
      <img src={mastercardIcon} alt='MasterCard' />  
      <img src={americanexpress} alt='AmericanExpress' />  
      <img src={applepay} alt='ApplePay' />  


      
          </div>

    </div>
  );
}

export default Footer;
