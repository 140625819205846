import React, { useMemo } from 'react';

const ImageFunctionality = (props) => {
  const { files } = props;

  const filesListWithBlobDataSrc = useMemo(() => {
    const fileWithBlob = [];
    files?.forEach(async (file) => {
      fileWithBlob.push({
        ...file,
        imgSrc: URL.createObjectURL(file.blobFile),
      });
    });
    return fileWithBlob;
  }, [files]);

  return (
    filesListWithBlobDataSrc?.length > 0 && (
      <div className='image-wrapper'>
        {filesListWithBlobDataSrc?.map((file) => {
          const { blobFile, id, imgSrc } = file;
          return (
            <div className='image-item' key={`${id}`}>
              <img className='choosed-image' alt={blobFile.name} src={imgSrc} />
              {/*<span className='filename'>{blobFile.name}</span>*/}
            </div>
          );
        })}
      </div>
    )
  );
};

export default ImageFunctionality;