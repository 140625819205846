/**
 * The function takes an image, crop dimensions, and file name, and returns a Promise that resolves to
 * a cropped image blob.
 * @param image - The image object that needs to be cropped.
 * @param crop - An object containing the coordinates and dimensions of the cropped area of the image.
 * It has the following properties:
 * @param fileName - The name of the file to be created from the cropped image.
 * @returns The function `getCroppedImg` returns a Promise that resolves to a Blob object representing
 * the cropped image in JPEG format.
 */
export const getCroppedImg = (image, crop, file, callBackFunctions) => {
  callBackFunctions?.onCropping?.();
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  const ctx = canvas.getContext('2d');
  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          callBackFunctions?.onRejectOrSuccess?.();
          return;
        }
        callBackFunctions?.onRejectOrSuccess?.();

        blob.name = file.blobFile.name;
        return resolve(blob);
      },
      file.blobFile.type,
      0.8
    );
  });
};