import React from 'react';
import './WarningPopup.css';

const WarningPopup = ({ title, popupcontent, onClose }) => {
  return (
    <>
      {/* The translucent background for the blur effect */}
      <div className="blur-background" />
      
      {/* The warning box */}
      <div className="warning-popup">
        <div className="warning-content">
          <h3>{title}</h3>
          <p>{popupcontent}</p>
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    </>
  );
};

export default WarningPopup;
