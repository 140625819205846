export default function imageSizeCheck(images) {
    return new Promise(async (resolve, reject) => {
      try {
        const failedImages = []; // Array to store names of files that did not meet the criteria
        const filteredImages = await Promise.all(images.map(async (image) => {
          try {
            const dimensions = await getImageDimensions(image.blobFile);
            if (dimensions.height >= 512 && dimensions.width >= 512) {
              console.log(`Image ${image.blobFile.name} - Width: ${dimensions.width}, Height: ${dimensions.height}`);
              return image;
            } else {
              failedImages.push(image.blobFile.name); // Store the filename of failed images
              console.log(`Image ${image.blobFile.name} - Width: ${dimensions.width}, Height: ${dimensions.height} - Did not meet the criteria`);
            }
          } catch (error) {
            console.error("Error getting dimensions:", error);
          }
          return null; // Return null for images that don't meet the criteria
        }));
  
        // Filter out null entries from filteredImages
        const validImages = filteredImages.filter((image) => image !== null);
  
        // Resolve with an object containing both valid images and failed image names
        resolve({ validImages, failedImages });
      } catch (error) {
        reject(error);
      }
    });
  }
  
  function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  }
  